<template>
  <ul class="nav nav-mobile-menu">
   <base-dropdown v-if="user" :title="user.name">
      <!-- <a class="dropdown-item">{{$t("Perfil")}}</a> -->
      <router-link tag="a" class="dropdown-item" to="change_password">{{$t("change_password")}} </router-link>
      <a class="dropdown-item " @click="showNotificationsModal()">{{$t("Gestionar notificaciones")}}</a>
      <div class="divider"></div>
      <a class="dropdown-item" @click="logout()">{{$t("Salir")}}</a>
    </base-dropdown>
  </ul>
</template>
<script>
import { mapGetters } from 'vuex';

  export default {
    name: 'mobile-menu',
    computed: {
        ...mapGetters({
            user: 'login/user'
        })
    },
    methods : {
      showNotificationsModal(){

      },
      logout() {
          this.$store.dispatch('login/logout');
      },
    }
  }
</script>
<style>
</style>
